

$show-mobile-filter-breakpoint: 1120px;

@mixin show-mobile-filters {
  @media (max-width: $show-mobile-filter-breakpoint - 1px) {
    @content;
  }
}

@mixin hide-mobile-filters {
  @media (min-width: $show-mobile-filter-breakpoint) {
    @content;
  }
}


.map-search {
  /* position: absolute; */
  /* top: 4rem; */
  /* left: 0; */
  // margin-right: 0.75rem;
	z-index: 10;
}



.map-search .mapboxgl-ctrl-geocoder{
	background-color: transparent;
}

.map-search .mapboxgl-ctrl-geocoder.mapboxgl-ctrl input{
  height: 3rem!important;
	border-radius: 8px!important;
	@include box-shadow();
	border: none;
	font-family: $font;
	color: $green-100;
	padding: 0 1rem;
	font-size: 0.875rem;

	::-webkit-input-placeholder {color: $green-80!important;}
  :-moz-placeholder           {color: $green-80!important;}
  ::-moz-placeholder          {color: $green-80!important;}
  :-ms-input-placeholder      {color: $green-80!important;}  
	@include placeholder{
		
	}
	&:focus{
		outline: none!important;
		@include box-shadow-focus();
	}
	@include medium-down{
		height: 4rem!important;
		border-radius: 1.25rem!important;
	}
}

.mobile-filter-menu__close{
	@include box-shadow();
	height: 3rem;
	width: 3rem;
	border-radius: 1rem;
	background-color: white;
	color: $green-90;
	font-size: 1rem;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;
}
.mobile-filter-menu__close:hover{
	background-color: $green-10;
}

.mobile-filter-menu{
	background-color: white;
	position: absolute;
	top: 1rem;
	right: 1rem;
	display: none;
	flex-direction: column;
	// padding: 0.5rem;
	width: 256px;
	min-height: calc(100vh - 2rem);
	border-radius: 12px;
	z-index: 11;
	@include xs{
		width: calc(100% - 2rem);
	}
}
.mobile-filter-label{
	font-size: 0.875rem;
	color: $gray-60;
	font-weight: 600;
	display: flex;
	margin-left: 0.5rem;
	margin-top: 0.5rem;
	// margin-bottom: 0.5rem;
}

@include show-mobile-filters(){
	.mobile-filter-menu{ display: flex;}
}



.mobile-filter-button.mobile-filter--cancel,
.mobile-filter-button.mobile-filter--apply{
	display: none;

}

.mobile-filter-button.mobile-filter--cancel,
.mobile-filter-button.mobile-filter--apply{
	width: auto;
	padding: 0 1.5rem;
	font-size: 0.875rem;
}

.mobile-filter--apply{
	position: absolute;
	right: 0;
}




.mobile-filter-toggle.mobile-filter-button{
	border-radius: 1rem;
	position:absolute;
	z-index: 10;
	top: 1rem;
	right: 3rem;
	background-color: white;
	@include box-shadow();
	@include hide-mobile-filters(){
		display: none;
	}
	@include show-mobile-filters(){
		right: 1rem;
	}
	@include large-down{
		position: fixed;
		top: unset;
		bottom: 1rem;
	}
	
}

.mobile-filter-button{
	// background-color: white;
	height: 4rem;
	width: 4rem;
	font-size: 0.75rem;
	font-weight: 600;
	color: $green-80;
	// border-radius: 4px;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

}


.mobile-filter-header{
	display: flex;
	width: 100%;
	height: 4rem;
	text-align: center;
	align-items: center;
	background-color: white;
	// @include small{
	// 	background-color: transparent;
	// }
}

.mobile-filter-results{
	display: flex;
	align-self: center;
	justify-self: center;
	font-size: 1rem;
	color: $gray-60;
	
}




.mobile-filter-toggle svg{
	display: flex;
}

.mobile-filter-toggle:focus{
	outline: none;
	-moz-box-shadow: 0 0 2px rgba(68, 184, 132, 0.5) ;
	-webkit-box-shadow: 0 0 2px  rgba(68, 184, 132, 0.5) ;
	box-shadow: 0 0 0 2px rgba(68, 184, 132, 0.5) ;
}

.mobileNavIsOpen{
	@include medium-down{
		.map-header-wrapper{
			display: none;
		}
	}
}

.mobileFilterMenuIsOpen .map-header-wrapper{
	@include small{
		display: flex;
		flex-direction: column;
	}
}

.map-header-wrapper{
	// overflow: hidden;
	margin-top: 12px;
	margin-left: 12px;
	position: fixed;
	z-index: 99;
	-webkit-transform: translate3d(0,0,1px);
	transform: translate3d(0,0,1px);
	top: 4rem;
	right: 4rem;
	@include medium-down{
		border-radius: 1rem;
		// z-index: 100000;
		// top: 4rem;
		right: 0;
		margin-right: 0.75rem;
		top: 4rem;
	}
	@include show-mobile-filters(){
		display: none!important;
	}
}

.map-header-wrapper.open{

	@include medium-down{
		background-color: $cream;
		width: 100%;
		overflow: hidden;
		position: relative;
		top: unset;
		left: unset;
		right: unset;
		bottom: unset;
		margin: 0.75rem;
		}
}

.map-header{
	// height: 3rem;
	display: flex;

	// background-color: blue;

		@include medium-down{
			// background-color: rgba(240, 245, 243, 0.9);
			// background-color: #f0f5f3;
			flex-direction: column;
			min-height: 400px;
			display: none;
			padding: 0.75rem;
			overflow: hidden;
			z-index: 1000;
			// top: 4rem;
			// left: 0;
			height: auto;
			border-radius: 8px;
		}
		@include small{
			// width: calc(100vw - 24px)
		}


}

.map-header.mobileFiltersOpen{
	display: flex;
	border-radius: 0 0 8px 8px;
	padding-bottom: 1rem;
}

.map-header__inner{
	display: flex;
	flex-direction: column;
	@include medium-down{
		flex-direction: column;
	}
	@include medium-up{
		align-items: center;
		justify-content: flex-end;
	}
}

.map-header__sort{
	display: flex;
	align-items: center;
	width: 256px;
	padding: 0 0.75rem;
	@include small{
		display: none;
	}
}

.large-filter-menu{
	display: flex;
	position: relative;
	@include medium-down{
		flex-direction: column;
		width: 100%;
		justify-content: stretch;
		margin-right: none;
	overflow: hidden;

	}
	@include large-up{
		flex-direction: row;
		margin-left: auto;
	align-items: center;

	}
}

.filter-toggle-wrapper{
	position: relative;
	// overflow: hidden;
	@include medium-down{
		margin-bottom: 8px;
	}
}

.map-search-wrapper{
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	margin: 0.75rem;
	z-index: 100;
	@include medium-down{
		top: 4rem;
		left: 0;
		position: fixed;
	}
}

.map-search-toggle{
	height: 3rem;
	width: 3rem;
	color: $green-90;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 0.875rem;
	@include box-shadow;
	background-color: white;
	border-radius: 12px;
	&:focus{
		@include box-shadow-focus;
		outline: none;
	}
	@include medium-down{
		height: 4rem;
		width: 4rem;
		font-size: 1.125rem;
		border-radius: 1.25rem;
	}
}

.map-search-backdrop{
	display: none;
}
.map-search-backdrop.visible{
	display: flex;
	width: 20rem;
	height: 100%;
	left: 3.5rem;
	@include medium-down{
		width: auto;
		// height: 4rem;
	}
}
.map-search{
		display: flex;
		width: 100%;
}


.filter-toggle{
	position: relative;
	display: flex;
	flex-direction: row;
	height: 2.5rem;
	min-width: 10rem;
	padding: 8px;
	background-color: white;
	color: $green-100;
	font-size: 0.875rem;
	align-items: center;
	border-radius: 8px;
	// border-left: solid 1px #ccc;
	// border-bottom: solid 1px #ccc;
	// border-right: solid 1px #ccc;
	width: auto;
	text-align: left;	
	@include medium-down{
		pointer-events: none;
		height: auto;
		width: 100%;
		margin: 1rem 0 0 0;
		background: transparent;
		padding: 0 8px;
		color: $gray-green-80;
		border: none;
		border-radius: 0;
		svg{
			display: none;
		}
	}
	@include large-up{
		height: 3rem;
		padding-left: 0.75rem;
		padding-right: 0.75rem ;
		-moz-box-shadow: 0 0 2px rgba(45, 120, 108, 0.1);
		-webkit-box-shadow: 0 0 2px  rgba(45, 120, 108, 0.1);
		box-shadow: 0 0 0 2px rgba(45, 120, 108, 0.1);
		margin: 0 0 0 12px;
	}
}

.filter-toggle:hover{
	background-color: $green-5;
	cursor: pointer;
	@include medium-down{
		background-color: $green-10;
	}
}
.filter-toggle:focus{
		outline: none!important;
		@include box-shadow-focus();
}

.filter-toggle__name{
	// color: #698782;
	color: $gray-green-60;

	font-weight: 500;
	letter-spacing: 0.0125em;
	font-size: 0.75rem;
	line-height: 1;
	@include medium-down{
		color: $green-90;
		font-size: 0.875rem;
	}
}

.filter-toggle__value{
	font-size: 1rem;
	font-weight: 500;
	line-height: 1;
	margin-top: 2px;
	color: $gray-green-80;
	@include medium-down{
		display: none;
	}
}


.filter-toggle .chevron{
	width: 0.625rem;
	height: auto;
	color: gray;
	transition: all, 0.3s;
}

.filter-toggle.isOpen .chevron{
	transform: rotate(180deg);
	fill: blue!important;
} 


.filter-menu-item-wrapper{
	@include medium{	
		width: unset;
		flex-basis: 0;
		flex: 1;

	}
	@include small{
		width: 100%;
	}
}


.filter-menu{
		display: none;
		// height: 0;
		// transition: all, 0.3s;
		// transform: scale(0.1);
		@include medium-down{
			display: flex!important;
		}
}

.filter-menu.open{
		display: flex;
		height: auto;
		transform: scale(1);
}

.filter-menu{
	margin-top: 0.75rem;
	// padding: 8px;
	// transform: translateY(-50%);

	// background-color: $cream;
	background-color: white;
	// background-color: #f0f5f3;
	padding: 8px;
	border-radius: 8px;
	flex-direction: column;
	min-width: 12rem;
	overflow: hidden;
	
	@include medium-down{
		display: flex;
		flex-direction: unset;
		position: relative;
		background: transparent;
		padding: 0 0 0 4px;
		margin: 0;
		padding-top: 0.5rem;
		flex-wrap: wrap;
	}
	@include large-up{
	margin-top: 0.5rem;
	position: absolute;
	--tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
		top: 100%;
		left: 0;
		z-index: 1000;
	}
}

// .filter-menu--count{
// 	right: 0px;
// 	left: unset;
// 	min-width: 12rem;
// }

.filter-tag-button{
	// padding: 4px;
	padding: 8px 4px;
	margin-top: -8px;
	display: flex;
	align-items: center;
	height: 100%;
	overflow: visible;
}

.filter-tag-button:focus{
	outline: none;
	.filter-tag{
		@include box-shadow-focus();
	}
}

.filter-tag .status-marker{
	height: 1rem;
	width: 1rem;
	border-radius: 0.5rem;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

.filter-tag.filter-tag--filterStatus{
	padding-left: 2px;
}

.filter-tag-button svg{
	opacity: 0.5;
}

.filter-tag-button:hover svg{
	opacity: 1;
}
.filter-tag.filter-tag--filterStatus.filter-tag--general{
	background-color: #fae2ab;
	color: #9d7d34;
	.status-marker{
		background-color: $general-marker-color;
	}
	&:hover{
		background-color: $general-marker-color;
		color: white;
	}
}

.filter-tag.filter-tag--filterStatus.filter-tag--rescued{
	// background-color: #aaeccb;
  color: $rescued-text-color;
	.status-marker{
		background-color: $rescued-marker-color;
	}
	&:hover{
		background-color: $rescued-marker-color;
		color: white;
	}
}

.filter-tag.filter-tag--filterStatus.filter-tag--deceased{
	background-color: #ccc;
  color: black;
	.status-marker{
		background-color: #000;
		margin-right: 2px;
	}
	&:hover{
		background-color: black;
		color: white;
	}
}

.filter-tag.filter-tag--filterStatus.filter-tag--urgent{
	background-color: #fbe3e5;
  color: #bd2d32;
	.status-marker{
		background-color: $urgent-marker-color;
		margin-right: 2px;
	}
	&:hover{
		background-color: $urgent-marker-color;
		color: white;
	}
}

.status-marker--rescued{
	background-color: blue;
}

.filter-tag{
	// border: solid 1px $green-80;
	pointer-events: initial;
	background: $green-10;
	height: 2.5rem;
	border-radius: 1.25rem;
	color: $green-80;
	display: flex;
	align-items: center;
	font-weight: 600;
	font-size: 0.875rem;
	padding: 0 8px 0 12px;
	margin-right: 4px;
	-moz-box-shadow: 0 0 2px rgba(45, 120, 108, 0.1);
	-webkit-box-shadow: 0 0 2px  rgba(45, 120, 108, 0.1);
	box-shadow: 0 0 0 2px rgba(45, 120, 108, 0.1);
}
.filter-tag:hover{
	background-color: $green-60;
	color: white;
}

.filter-tag svg{
	height: 0.875rem!important;
	width: 0.875rem!important;
	margin-right: 0.25rem;
	margin-left: 0.25rem;
}

.filter-tags{
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	overflow-x: scroll;
	padding-left: 0.5rem;
	overflow: visible;
	z-index: 100;
	pointer-events: none;
	position: absolute;
	top: 4rem;
	right: 2rem;
	margin-right: 2rem;
	justify-content: flex-end;
	z-index: 1;
	margin-top: 0.875rem;
	@include small{
		width: calc(100% - 4rem);
		position: fixed;
		top: unset;
		bottom: 0;
		left: 0;
		z-index: 0;
		margin-right: 4rem;
		// background: blue;
	}
}

.filter-tags .filter-tag-button:first-child .filter-tag{
	// margin-left: 8px;
}
.filter-tags .filter-tag-button:last-child .filter-tag{
	// margin-right: 8px;
}


.status-marker-multiple-wrapper{
	margin-left: auto;
	display: flex;
	justify-content: flex-end;
	justify-self: flex-end;
}

// .status-marker-multiple-wrapper .status-marker{
// 	margin-right: -4px;
// }
// .status-marker-multiple-wrapper .status-marker:last-child{
// 	margin-right: 0;
// }

// .filter-menu-item .status-marker{
// 	margin-left: 0.25rem;
// }




.status-marker{
	display: inline-flex;
	width: 0.75rem;
	height: 0.75rem;
	border-radius: 50%;
	border: solid 2px #fff;
	// margin-left: auto;
}

.filter-menu-item .status-marker{
	@include large-up{
		height: 0.875rem;
		width: 0.875rem;
	}
}



.large-filter-menu .filter-toggle-wrapper:last-child{
	margin-bottom:0;
}

.filter-menu div:last-child .filter-menu-item{
	margin-bottom: 0;
}

.filter-menu-item:focus-within{
	outline: solid 2px $green-60;
	outline-offset: -2px;
}

.filter-menu-item__text{
	color: $green-100;
	font-weight: 400;
	margin-left: 0.25rem;
	font-size: 1rem;
	line-height: 1;
}

.filter-menu-item.selected{
	.filter-menu-item__additional-text{
		display: none;
	}
}

.filter-menu-item__additional-text{
	color: $gray-70;
	display: flex;
	flex-wrap: nowrap;
	position: absolute;
	font-size: 0.75rem;
	right: 0.5rem;
	// background-color: white;
}


.filter-menu-item{
	padding: 0.25rem ;
	width: 100%;
	height: 2.5rem;
	font-size: 1rem;
	background-color: white;
	border-radius: 12px;
	// margin-bottom: 4px;
	position: relative;
	align-items: flex-start;
	// border: solid 2px $gray-10;
	@include medium-down{
		height: 3rem;
		margin: 0.25rem;
		padding: 0.25rem 0.75rem;
	}
}

.filter-radio__outer-circle{
	width: 1rem;
	height: 1rem;
	border-radius: 0.5rem;
	// border: solid 2px $sage-30;
	border: solid 2px $gray-green-40;
	margin: 0 0.5rem 0 0.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.filter-radio__inner-circle{
	width: 0.5rem;
	display: flex;
	height: 0.5rem;
	border-radius: 0.5rem;
	background-color: white;
}

.filter-menu-item.selected .filter-radio__outer-circle{
	background-color: $green-80;
	border-color: $green-80;
}


.filter-menu-item.filter-menu-item--count{
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.filter-menu-item.filter-menu-item--status{
	justify-content: flex-start;
	padding-left: 0.25rem;
	flex-direction: row;
}

.filter-menu-item .status-marker{
	margin-right: 0.25rem;
	margin-left: auto;
	height: 1.125rem;
	width: 1.125rem;
	@apply box-shadow;
}

.filter-menu-item{
	@include medium-down;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	.filter-menu-item__text{
		margin-left: 0;
	}
}

.filter-menu-item.selected .filter-menu-item__check-wrapper{
	background-color: $green-80;
	border-color: $green-80;
}

.filter-menu-item+input[type='checkbox']{
	display: none;
}

.filter-menu-item__check-wrapper{
	height: 1rem;
	width: 1rem;
	display: flex;
	border: solid 2px $sage-30;
	border-radius: 2px;
	margin-right: 0.5rem;
	margin-left: 0.25rem;
	display: flex;
	justify-content: center;
	align-items: center;
}
.filter-menu-item__check{
	width: 0.625rem!important;
	height: 0.625rem!important;
	color: white;
}

.filter-menu-item.selected{
	// background-color: $green-10;
	// border-color: $green-10;
	// color: white;

}
.filter-menu-item.selected:hover{
	// background-color: $green-60;
}

.filter-menu-item:hover{
	// background-color: $green-10;
	background-color: $gray-green-20;
	border-color: $green-10;
	cursor: pointer;
}

.filter-check{
	height: 0.5rem;
	color: white;
}

.filter-menu-item+input[type='radio']{
	height: 0.01px;
	width: 0.01px;
	position: absolute;
	left: -100000px;
	opacity: 0;
}


.mobile-filter-menu .clear-filter-button{
	position: relative;
	margin:0 1rem 1rem 1rem;
}



.clear-filter-button{
	font-size: 0.875rem;
	color: $green-80;
	height: 3rem;
	padding: 8px;
	font-weight: 600;
	border-radius: 0.5rem;
	margin-left: 8px;
	margin-right: 8px;
	margin-bottom: 8px;
	background-color: white;
	@include box-shadow();
	@include large-up{
		display: none;
		position: absolute;
		top: 100%; right: 0;
		color: $green-80;
		margin-left: 0;
		margin-right: 0;
		margin-top: 8px;
		background-color: white;
		@include box-shadow();
		height: 3rem;
		border-radius: 8px;
	}
}
.clear-filter-button:hover{
		background-color: $green-10;
}

.filter-backdrop{
	display: none;
	@include medium-down{
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1000;
	display: block;
	background-color: $green-90;
	opacity: 0.6;
	}
}



