
/* Basics */
.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl-geocoder *,
.mapboxgl-ctrl-geocoder *:after,
.mapboxgl-ctrl-geocoder *:before {
  box-sizing: border-box;
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
  width: 100%;
  max-width: 100%;
}

.geocoder-pin-right {
  display: none;
}

#report-form-geocoder:focus-within{
	outline: none;
	@include box-shadow-focus;
	border-radius: 8px;
}
#report-form-geocoder input:focus{
	outline: none;
	@include box-shadow-focus;

}
#report-form-geocoder.hasError input{
	-moz-box-shadow: 0 0 2px var(--red-40);
	-webkit-box-shadow: 0 0 2px  var(--red-40);
	box-shadow: 0 0 0 2px var(--red-40);

}

#report-form-geocoder input{
	height: 4rem;
	padding-left: 2.5rem;
	border-radius: 8px;
	font-family: var(--main-font-family);
	font-size: 1rem;
}



.mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
  box-shadow: none;
}
.mapboxgl-ctrl-geocoder.mapboxgl-ctrl input[type="text"] {
  @extend .form-field__input;
  margin-top: 0;
}


.mapboxgl-ctrl-geocoder input {
  font: inherit;
  width: 100%;
  border: 0;
  background-color: transparent;
  margin: 0;
  height: 4rem;
  color: #404040; /* fallback */
  color: rgba(0, 0, 0, 0.75);
  color: purple;
  padding: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mapboxgl-ctrl-geocoder--input::-ms-clear {
  display: none; /* hide input clear button in IE */
}

.mapboxgl-ctrl-geocoder--input:focus {
  color: #404040; /* fallback */
  color: rgba(0, 0, 0, 0.75);
  color: red;
  outline: 0;
  box-shadow: none;
  outline: thin dotted\8;
}

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > * {
  z-index: 2;
  position: absolute;
	background-color: transparent;
	top: 0;
	right: 0;
  width: 3rem;
	height: 3rem;
  display: none;
}

svg.mapboxgl-ctrl-geocoder--icon.mapboxgl-ctrl-geocoder--icon-search{
  top: 50%;
  transform: translateY(-50%);
  margin-left: 0.25rem;
}




/* Collapsed */
.mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
  width: 50px;
  min-width: 50px;
  transition: width 0.25s, min-width 0.25s;
}

/* Suggestions */
.mapboxgl-ctrl-geocoder .suggestions {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
}
.mapboxgl-ctrl-geocoder .suggestions{
	border-radius: 12px;
}

.mapboxgl-ctrl-geocoder .suggestions > li > a{
	font-size: 0.875rem;
	padding: 0.75rem 1rem;
}



.mapboxgl-ctrl-geocoder .suggestions {
  background-color: #fff;
  border-radius: 4px;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  top: 110%; /* fallback */
  top: calc(100% + 6px);
  z-index: 1000;
  overflow: hidden;
  font-size: 15px;
}

.mapboxgl-ctrl-bottom-left .suggestions,
.mapboxgl-ctrl-bottom-right .suggestions {
  top: auto;
  bottom: 100%;
}

.mapboxgl-ctrl-geocoder--suggestion-title, .mapboxgl-ctrl-geocoder--suggestion-address{
  color: $gray-green-90;
}

.mapboxgl-ctrl-geocoder .suggestions > .active > a{
  background-color: $gray-green-20;
	// color: white;
  text-decoration: none;
  cursor: pointer;
}
.mapboxgl-ctrl-geocoder .suggestions > li > a:hover {
  background-color: $gray-green-10;
	// color: white;
  text-decoration: none;
  cursor: pointer;
}

.mapboxgl-ctrl-geocoder--suggestion-title {
  font-weight: bold;
}

.mapboxgl-ctrl-geocoder--suggestion-title,
.mapboxgl-ctrl-geocoder--suggestion-address {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* Icons */
.mapboxgl-ctrl-geocoder--icon {
  display: inline-block;
  vertical-align: middle;
  speak: none;
  fill: #757575;
  top: 15px;
}

/* .mapboxgl-ctrl-geocoder--icon-search {
  position: absolute;
  top: 0.75rem;
  right: 12px;
  bottom: unset;
  left: unset;
  width: 1.5rem;
  height: 1.5rem;
} */

.mapboxgl-ctrl-geocoder--button {
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  background: #fff;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%!important;
  transform: translateY(-50%)!important;
  right: 0.5rem!important;
  height: 100%;
  background-color: transparent;
}

.mapboxgl-ctrl-geocoder--button:hover{
  background-color: transparent!important;
}

.mapboxgl-ctrl-geocoder--button:hover .mapboxgl-ctrl-geocoder--icon-close {
  fill: #909090;
  margin-top: 0!important;
}

.mapboxgl-ctrl-geocoder--icon-close {
	margin-top: 0;
  // width: 20px;
  // height: 20px;
  // margin-top: 8px;
  // margin-right: 3px;
}



.mapboxgl-ctrl-geocoder--icon-loading {
  width: 26px;
  height: 26px;
  margin-top: 5px;
  margin-right: 0px;
  -moz-animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -webkit-animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
  animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

/* Animation */
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Media queries*/
@media screen and (min-width: 640px) {
  .mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
    width: 36px;
    min-width: 36px;
  }

  .mapboxgl-ctrl-geocoder .suggestions {
    font-size: 13px;
  }

  .mapboxgl-ctrl-geocoder--icon {
    /* top: 8px; */
  }

  .mapboxgl-ctrl-geocoder--icon-close {
    width: 16px;
    height: 16px;
    margin-top: 3px;
    margin-right: 0;
    color: purple;
  }


  // .mapboxgl-ctrl-geocoder--input {
  //   height: 36px;
  //   padding: 6px 35px;
  // }

  .mapboxgl-ctrl-geocoder--icon-loading {
    width: 26px;
    height: 26px;
    margin-top: -2px;
    margin-right: -5px;
  }

  .mapbox-gl-geocoder--error {
    color: #909090;
    padding: 6px 12px;
    font-size: 16px;
    text-align: center;
  }
}


