// $font: 'Work Sans', sans-serif;
$font: 'Fabriga';
// $font: 'Inter';

// $font: 'Space Grotesk', sans-serif;

$serif: 'GT Super Display';

$sage-5: #F9FBFA;
$sage-10: #E4ECE8;
$sage-20: #C7D1CC;
$sage-30: #BCD7D5;

$gray-green-10: #F1F7F5;
$gray-green-20: #E7EEEC;
$gray-green-30: #D6E1DE;
$gray-green-40: #C0CECA;
$gray-green-50: #9DAFAA;
$gray-green-60: #85938F;
$gray-green-70: #697773;
$gray-green-80: #4A5955;
$gray-green-90: #384743;
$gray-green-100: #1D352E;


$green-5: #F7FDFB;
$green-10: #E4F7F2;
$green-10: #e5f6f0;
$green-20: #CAFAE6;
$green-30: #C6EBE2;
$green-40: #47E6B9;
$green-50: #00D29C;
$green-60: #00B884;
$green-70: #00967D;
$green-80: #00786C;
$green-90: #005C5A;
$green-100: #00382B;

$gray-10: #f7f3f2;
$gray-20: #e5e0df;
$gray-30: #cac5c4;
$gray-40: #ada8a8;
$gray-50: #8f8b8b;
$gray-60: #736f6f;
$gray-70: #565151;
$gray-80: #3c3838;
$gray-90: #272525;
$gray-100: #171414;


$red-10:  #fcf8f6;
$red-20: #fcefee;
$red-30: #fad5db;
$red-40: #f9b1ba;
$red-50: #fa7c86;
$red-60: #fa5158;
$red-70: #f3323a;
$red-80: #d52634;
$red-90: #aa1f2e;
$red-100: #861a27;


$cream: #faf8f5;
$cream: #F9F8F5;
$cream: #fcfbfa;

$nav-height: 4rem;

$special-yellow: #f5c75c;
$special-yellow-light: #FFE2B3;
$special-blue: #87d2d9;
$special-blue-light: #bce7e9;
$special-red: #e8af84;
// $special-red-light: #F8D4BF;
$special-red-light: #FCE0CF;
$special-green: #83ceb3;
$special-green-light: #B7E6D9;

$rescued-marker-color: #48c08b;
$rescued-text-color: $green-90;
$rescued-bg-color: $special-green-light;

$urgent-marker-color: $special-red;
$urgent-text-color: #9A4818;
$urgent-bg-color: $special-red-light;

$general-marker-color: $special-yellow;
$general-text-color: #8D682A;
$general-bg-color: $special-yellow-light;

$deceased-marker-color: $green-100;
$deceased-text-color: #fff;
$deceased-bg-color: $green-100;



$deceased-marker-color: #000;


$mobile-map-height: 50vh;

$mobile-map-width: 100vw;




$rescued-marker-color: $special-green;
$urgent-marker-color: $special-red;
$general-marker-color: $special-yellow;