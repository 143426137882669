
.dropzone--hidden {
  visibility: hidden;
}

.uploader__dropzone {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  border-radius: 0.5rem;
  cursor: pointer;
  background-color: white;
	padding: 0.5rem;
	color: var(--form-text-color);
}

.uploader__inner{
  border: dashed 1px var(--sage-20);
  padding: 3rem;
  border-radius: 0.25rem;
}
.uploader__inner:hover {
  border-style: solid;
}

.uploader__input{
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.image-upload__progress{
  // background-color: blue;
}

progress[value]::-webkit-progress-value{
	background-color: var(--green-60);
}

progress[value] {
  // /* Reset the default appearance */
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
	height: 0.5rem;

}
progress[value]::-webkit-progress-bar{
  background-color: var(--sage-10);
  border-radius: 0.5rem;
  overflow: hidden;
	height: 0.5rem;
}

.uploader__dropzone.dragging {
  background-color: var(--green-10);
}