@mixin box-shadow{
	-moz-box-shadow: 0 0 2px rgba(45, 120, 108, 0.1);
	-webkit-box-shadow: 0 0 2px  rgba(45, 120, 108, 0.1);
	box-shadow: 0 0 0 2px rgba(45, 120, 108, 0.1);
}
@mixin box-shadow-thin{
	-moz-box-shadow: 0 0 1px rgba(45, 120, 108, 0.1);
	-webkit-box-shadow: 0 0 1px  rgba(45, 120, 108, 0.1);
	box-shadow: 0 0 0 1px rgba(45, 120, 108, 0.1);
}

@mixin box-shadow-focus{
	// -moz-box-shadow: 0 0 2px rgba(45, 120, 108, 0.4);
	// -webkit-box-shadow: 0 0 2px  rgba(45, 120, 108, 0.4);
	// box-shadow: 0 0 0 2px rgba(45, 120, 108, 0.4);
}

.box-shadow{
  @include box-shadow();
}

.box-shadow:focus{
  @include box-shadow-focus();
}


$sm: 480px;
$md: 672px;
$lg: 864px; 
$xl: 960px;
$max: 1312px;

@mixin xs {
  @media (max-width: $sm - 1px) {
    @content;
  }
}

@mixin small {
  @media (max-width: $md - 1px) {
    @content;
  }
}

@mixin medium {
  @media (min-width: #{$md}) and (max-width: #{$lg - 1px}) {
    @content;
  }
}

@mixin medium-down {
  @media (max-width: #{$lg - 1px}) {
    @content;
  }
}

@mixin medium-up {
  @media (min-width: #{$md}) {
    @content;
  }
}

@mixin large {
  @media (min-width: #{$lg}) and (max-width: #{$xl - 1px}) {
    @content;
  }
}

@mixin large-down {
  @media (max-width: #{$xl - 1px}) {
    @content;
  }
}

@mixin large-up {
  @media (min-width: #{$lg}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$xl}) and (max-width: #{$max - 1px}) {
    @content;
  }
}

@mixin xl-down {
  @media (max-width: #{$xl - 1px}) {
    @content;
  }
}

@mixin xl-up {
  @media (min-width: #{$xl}) {
    @content;
  }
}

@mixin max {
  @media (min-width: #{$xl}) {
    @content;
  }
}


@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
}





