
@import "_variables";

.admin-page {
  // background-color: #f9f8f4;
}

.admin-page-heading {
  font-size: 2rem;
  color: #455c52;
  font-weight: 600;
}

.admin-sightings-table {
  max-width: 992px;
  display: flex;
  flex-direction: column;
  // background-color: white;
}

.admin-sighting-row {
  border-bottom: solid 1px $sage-10;
  padding: 8px 0;
  background-color: white;
}

.admin-sighting-row:hover {
  background-color: $sage-5;
}

.admin-nav {
  width: 100%;
  display: flex;
  height: 4rem;
  align-items: center;
  background-color: white;
  // position: absolute;
  // top: 0;
  // left: 0;
  border-bottom: solid 1px $sage-10;
}

.admin-page-container {
  max-width: 992px;
  width: 100%;
  margin: 0 auto 0 auto;
  padding: 0 16px;
}


// .single-sighting-map {
//   width: 100%;
// }

.admin-sighting-tab.active{
border-top-color: $green-70;
font-weight: 600;
background-color: white;
}

.admin-sighting-tab{
  background-color: $sage-10;
  height: 3rem;
  font-size: 0.875rem;
  color: $green-100;
  padding: 0 1rem;
  border-bottom: solid 1px $sage-10;  
  border-top: solid 4px transparent;
}

