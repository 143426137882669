// @import '../../../sass/forms';

.react-datepicker{
  // font-family: $font;
  border: solid 1px var(--sage-10);
}
.react-datepicker__header{
  background-color: var(--sage-5);
  border-bottom-color: var(--sage-10);
}
.react-datepicker__day{
	border-radius: 1rem;
}
.react-datepicker__day:hover{
  background-color: var(--gray-green-20);
	border-radius: 1rem;
}
.react-datepicker__day.react-datepicker__day--selected,
.react-datepicker__day.react-datepicker__day--keyboard-selected{
  background-color: var(--green-80);
  color: white;
}


.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day{
  color: var(--form-text-color);
}
.react-datepicker__day.react-datepicker__day--disabled{
  color: #ccc;
}

.react-datepicker__triangle::before{
  border-top-color: var(--green-80);
}

.react-datepicker__today-button{
  background-color: var(--green-70);
  color:white;
  margin: 0.25rem;
  border-radius: 0.25rem;
  border: none;
}
.react-datepicker__today-button:hover{
  background-color: var(--green-80);
  color:white;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
  display: block;
}
.react-datepicker__day--keyboard-selected{
  background-color: var(--green-70);
}

