.form-field-group.no-circle .form-field__radio-circle{
  display: none;
}

.form-field-group{
	padding: 0.5rem 1rem;
	margin: 0 -1rem 0.5rem -1rem;
}



.form-field__label {
  font-size:1.125rem;
  line-height: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: var(--field-label-color);
  display: flex;
  align-items: flex-end;
}