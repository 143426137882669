/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;800&display=swap'); */

* {
  font-family: "Fabriga";
   /* font-family: 'Work Sans', sans-serif; */
   /* font-family: 'Space Grotesk';  */
   /* font-family: 'Inter'; */
}

@font-face {
  font-family: "Fabriga";
  src: url("./fonts/Fabriga-Bold.woff") format("woff"),
      url("./fonts/Fabriga-Bold.woff2") format("woff2"),
      url("./fonts/Fabriga-Bold.eot") format("eot");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Fabriga";
  src: url("./fonts/Fabriga-Regular.woff") format("woff"),
      url("./fonts/Fabriga-Regular.woff2") format("woff2"),
      url("./fonts/Fabriga-Regular.eot") format("eot");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Fabriga";
  src: url("./fonts/Fabriga-Light.woff") format("woff"),
      url("./fonts/Fabriga-Light.woff2") format("woff2"),
      url("./fonts/Fabriga-Light.eot") format("eot");
  font-weight: 300;
  font-style: normal;
}





.page-container{
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}



.sightingDetails .form-step--location,
.personalDetails .form-step--location {
  display: none;
}

.button__init-geolocate {
  width: 100%;
  background-color: #f2f4f8;
  height: 40px;
  position: relative;
  margin-top: 8px;
}

.button__init-geolocate.initiated {
  background-color: orange !important;
}

.button__init-geolocate.hidden {
  visibility: hidden;
}

.app-wrapper {
  /* display: flex; */
}

.app-wrapper main {
  display: flex;
  position: relative;
}


.mapboxgl-canvas {
  width: 100vw;
}




.mapboxgl-map {
  /* height: calc(100vh - 64px);
  width: calc(100vw - 320px); */
  position: relative;
}

.mapboxgl-map:focus-within{
  outline: none;
}

.map-wrapper {
  /* width: 100vw; */
  position: relative;
}

.report-cta {
  margin-left: auto;
  margin-right: 0;
  /* position: absolute; */
  /* bottom: 32px;
  left: 32px;
  z-index: 100; */
}



.form-step__inner {
  padding-top: 32px;
  position: relative;
}

.report {
  max-width: 800px;
}


.popup-title {
  color: orange;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}





.lds-ellipsis div {
  position: absolute;
  top: 50%;
  margin-top: -4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: blue;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 24px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 32px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(8px, 0);
  }
}



nav {
  height: 48px;
  display: flex;
  width: 100%;
  background-color: #fff;
  border-bottom: solid 1px #e0e0e0;
  align-items: center;
  z-index: 150;
  position: relative;
}

.nav-col {
  /* width: 50vw; */
  display: flex;
}

.nav-col:last-child {
  justify-content: flex-end;
  /* margin-right: 8px; */
  margin-left: auto;
}


.back-button {
  color: #20555d;
  font-size: 0.875rem;
}




.flag {
  font-size: 0.75rem;
  font-weight: 600;
  padding: 2px 12px;
  border-radius: 16px;
  background-color: turquoise;
  color: white;
  display: inline-block;
  margin: 0 4px;
}

.flag.injured {
  background-color: coral;
}
.flag.babies-present {
  background-color: #ecdbc9;
  color: #1e5258;
}

.flag:last-child {
  margin-right: 0;
}

.report-modal__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(0, 248, 245, 0.8); */
  z-index: 10;
}

.report-modal__backdrop {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(250, 248, 245, 0.6);
  z-index: 100;
  backdrop-filter: blur(8px);
}

.report-modal {
  width: 100%;
  max-width: 768px;
  z-index: 200;
}

.report-map {
  height: 300px;
  background-color: seashell;
  display: block;
  width: 100%;
}

.report-map .mapboxgl-canvas{
  width: 100%;
  height: 100%;
}

.mapboxgl-popup-content{
  border-radius: 12px;
  padding: 0;
  overflow: hidden;
}

.mapboxgl-popup-content .tag{
  height: 1rem;
  align-items: center;
  font-size: 0.5rem;
  font-weight: 600;
  background-color: #FAD5DB;
  color: #891535;
  padding: 0 8px;
  border-radius: 12px;
  margin-right: 4px;
  display: inline-flex;
}

.mapboxgl-popup-close-button{
  top: 4px;
  right: 4px;
  width: 32px;
  height: 32px;
  background-color: white;
  border-radius: 16px;
}

.mapboxgl-popup-close-button{
  background-color: #f4f4f4;
}
