.sighting-list-card{
	// @include box-shadow-thin;
}

.sighting-list-filters{
	padding-top: 1rem;
	@include medium-up{
		position: sticky;
		top: 64px;
		z-index: 1;
		padding-bottom: 0.5rem;
	}

	border-radius: 1rem 1rem 0 0;
	// &::before{
	// 	z-index: -1;
	// 	content: '';
	// 	position: absolute;
	// 	bottom: -1rem;
	// 	height: 1rem;
	// 	width: 100%;
	// 	display: block;
	// 	background-image: linear-gradient(rgba(255,255,255,1), rgba(255,0,0,0) );
	// }
}

.sighting-list__filter-tags .filter-tag-button{
	margin: 0 4px 0 0 !important;
	margin-top: 0!important;
}

.sighting-list__filter-tags{
	position: relative;
	background-color: white;
	padding: 4px 1rem;
	@include small{
		position: sticky;
		top: 64px;
	}
}

.sighting-list-card{
	@include small{
		padding-top: 1rem!important;
		padding-bottom: 1rem!important;
	}
}

.sighting-list-card:hover{
	background-color: $sage-5;
}

.sighting-list-card:focus{
	@include box-shadow-focus;
	outline: none!important;
}

.sighting-list__select{
	height: 3rem;
	padding: 0 1rem;
	display: flex;
	border: solid 2px $gray-green-20;
	@include small{
		margin-bottom: 0.5rem;
	}
}