.admin-single-sighting-map {
  height: 400px;
  width: 100%;
}

.single-sighting__save-btn {
  margin-left: auto;
  margin-right: 16px;
  background-color: #2a7d63;
  color: white;
  padding: 4px 16px;
  border-radius: 4px;
}

.single-sighting__save-btn.disabled {
  background-color: grey;
}

.admin-single-sighting-map {
  display: none;
}

