.form-tabs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 4rem;
  z-index: 5;
  background-color: var(--sage-5);
  margin-bottom: 1rem;
}

.form-tabs__container{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 640px;
}

.form-tabs__tab {
	border-radius: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 1rem;
  width: 100%;
  font-weight: 400;
  padding:  0.5rem 1rem;
  height: 3rem;
  text-align: left;
  border-bottom: solid 3px transparent;
  color: var(--field-label-color);
  span{
    display: flex;
  }
	&:hover:not([disabled]){
		background-color: var(--sage-5);
    border-bottom-color: var(--sage-20);
	}
  &[disabled]{
    color: var(--gray-green-60);
    cursor: not-allowed;
  }
}
.form-tabs__tab.active {
  // background-color: #19484a;
  // color: #333;
	// color: white;
  // font-weight: 500;
  // color: $gray-green-90;
  // border-bottom-color: $sage-20;
  font-weight: 600;
	// border-bottom: solid 1px $green-80;
}

.form-step-tab-wrapper{
  // background-color: transparent;
  // background-color: $cream;
  // background-color: white;
  // border-bottom: solid 1px $sage-10;
  z-index: 9;
  // padding: 0 1rem;
  position: sticky;
  top: 0;
  // @include medium-down{
  //   position: relative;
  // }
  // border-top: solid 1px $sage-10;
}

.form-step-tab-wrapper .container{
  // @include medium-down{
  //   padding: 0;
  // }
}


