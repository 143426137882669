.form-footer__button{
  height: 3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: white;
	border-radius: 0.75rem;
  // @include box-shadow();
	border: solid 1px var(--gray-green-20);
	padding: 0 1.5rem;
	font-size: 0.875rem;
	font-weight: 600;
	color: var(--field-label-color);
	background-color: transparent;
}

.form-footer__button:hover{
	background-color: white;
}

.form-footer__button:focus{
	outline: dashed 1px var(--focus-ring-color);
	outline-offset: 2px;
}

.form-footer__button--enabled{
	background-color: var(--green-70);
	color: white;
	border-color: transparent;
}
.form-footer__button--enabled:hover{
	background-color: var(--green-80);
}



