@import "./_variables";
@import "./_mixins";
@import "../css/tailwind-output.css";
@import "./_forms";
@import "./_map";
@import "./_report-form";
@import "./_MapHeader";
@import "./_SightingModal";
@import "./_sidebar";
@import "./sighting-list";

@import "./mapbox-geocoder";
@import "./algolia-search";


@import "./sighting-tags";

@import "./single-sighting-page";


@import "./_nav";

:root, html{
  --field-label-color: #{$gray-green-80};

  --form-highlight: #007d79;
  --focus-ring-color:#8f8b8b;
  --form-text-color: #4A5955;

  --global-text-color:#384743; 

  --gray-10: #f7f3f2;
  --gray-20: #e5e0df;
  --gray-30: #cac5c4;
  --gray-40: #ada8a8;
  --gray-50: #8f8b8b;
  --gray-60: #736f6f;
  --gray-70: #565151;
  --gray-80: #3c3838;
  --gray-90: #272525;
  --gray-100: #171414;

  --gray-green-10: #F1F7F5;
  --gray-green-20: #E7EEEC;
  --gray-green-30: #D6E1DE;
  --gray-green-40: #C0CECA;
  --gray-green-50: #9DAFAA;
  --gray-green-60: #85938F;
  --gray-green-70: #697773;
  --gray-green-80: #4A5955;
  --gray-green-90: #384743;
  --gray-green-100: #1D352E;

  --green-5: #F7FDFB;
  --green-10: #E4F7F2;
  --green-10: #e5f6f0;
  --green-20: #CAFAE6;
  --green-30: #C6EBE2;
  --green-40: #47E6B9;
  --green-50: #00D29C;
  --green-60: #00B884;
  --green-70: #00967D;
  --green-80: #00786C;
  --green-90: #005C5A;
  --green-100: #00382B;

--red-10:  #fcf8f6;
--red-20: #fcefee;
--red-30: #fad5db;
--red-40: #f9b1ba;
--red-50: #fa7c86;
--red-60: #fa5158;
--red-70: #f3323a;
--red-80: #d52634;
--red-90: #aa1f2e;
--red-100: #861a27;

  --sage-5: #F9FBFA;
  --sage-10: #E4ECE8;
  --sage-20: #C7D1CC;
  --sage-30: #BCD7D5;

  --main-font-family: 'Fabriga';

}

body{
  // background-color: #faf8f5;
  -webkit-overflow-scrolling: touch!important;
  width: 100vw;
  // background-color: $sage-5;
  font-family: $font;
  color: var(--global-text-color);
}
.serif{
  font-family: $serif;
}

.btn {
  padding: 4px 16px;
  border-radius: 8px;
  background-color: blue;
  color: white;
}

.top-level-app-wrapper{
  display: flex;
  flex-direction: column;
  @include xl-up{
    // flex-direction: row;
  }
}

.text-xxs{
  font-size: 10px;
}


// ███╗   ███╗███████╗██████╗ ██╗██╗   ██╗███╗   ███╗      ██████╗  ██████╗ ██╗    ██╗███╗   ██╗    
// ████╗ ████║██╔════╝██╔══██╗██║██║   ██║████╗ ████║      ██╔══██╗██╔═══██╗██║    ██║████╗  ██║    
// ██╔████╔██║█████╗  ██║  ██║██║██║   ██║██╔████╔██║█████╗██║  ██║██║   ██║██║ █╗ ██║██╔██╗ ██║    
// ██║╚██╔╝██║██╔══╝  ██║  ██║██║██║   ██║██║╚██╔╝██║╚════╝██║  ██║██║   ██║██║███╗██║██║╚██╗██║    
// ██║ ╚═╝ ██║███████╗██████╔╝██║╚██████╔╝██║ ╚═╝ ██║      ██████╔╝╚██████╔╝╚███╔███╔╝██║ ╚████║    
// ╚═╝     ╚═╝╚══════╝╚═════╝ ╚═╝ ╚═════╝ ╚═╝     ╚═╝      ╚═════╝  ╚═════╝  ╚══╝╚══╝ ╚═╝  ╚═══╝    
// < 864px                                                                                                
                                                          





.mapboxgl-canvas {
  width: 100vw;
}

.container{
  width: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: 1068px;
	padding: 0 1rem;
}

.mobile-navbar{
  display: flex;
  align-items: center;
  // padding-left: 1rem;
  height: 4rem;
  background-color: white;
  z-index: 100;
  position: fixed;
  border-bottom: solid 1px $gray-green-20;
  width: 100%;
  
  @include xl-up{
    width: calc(100vw - 256px);
  left: 256px;
  }
}


// .app-wrapper{
//   position: fixed;
//   top: 0;
//   left: 20rem;
// }

.app-main{
  width: calc(100vw - 256px);
  margin-left: 256px;
  margin-top: 4rem;
  @include large-down{
    width: 100vw;
    height: calc(100vh - 4rem);
    margin-left: unset;
  }
  @include medium-down{
    margin-top: 4rem;
  }
}




.mapboxgl-map:focus-within{
  outline: none;
}
.tag{
  height: 1rem;
  display: inline-flex;
  align-items: center;
  border-radius: 8px;
  font-size: 10px;
  font-weight: 500;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.tag.red{
  background-color: $red-20;
  color: #C51616;

}


@keyframes skeleton{
  0%{right:auto;left:0;width:0%;opacity:0.3}
  20%{right:auto;left:0;width:100%;opacity:1}
  28%{right:0;left:auto;width:100%}
  51%{right:0;left:auto;width:0%}
  58%{right:0;left:auto;width:0%}
  82%{right:0;left:auto;width:100%}
  83%{right:auto;left:0;width:100%}
  96%{right:auto;left:0;width:0%}
  100%{right:auto;left:0;width:0%;opacity:0.3}
}


.loading-skeleton{
  position: relative;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  background: $gray-green-20;
  overflow: hidden;
  border-radius: 0.5rem;
  color: $gray-green-80;
  padding: 1rem;
}

.loading-skeleton:before{
  content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(160, 154, 143, 0.1);
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
    animation: 3000ms ease-in-out skeleton infinite;
}
.bg--all{
  background-color: #ccc;
  }

.bg--urgent{
background-color: $urgent-marker-color;
}
.bg--general{
background-color: $general-marker-color;
}
.bg--deceased{
background-color: $deceased-marker-color;
}
.bg--rescued{
 background-color: $rescued-marker-color; 
}

.button.button--green{
  background-color: $green-60;
}


.button{
  display: inline-flex;
  align-items: center;
  border-radius: 12px;
  background-color: white;
  font-size: 0.875rem;
  @include box-shadow;
  color: $green-90;
  height: 4rem;
  padding: 0 1rem;
  transition: all, 0.3s;
}

.button.button--sm{
  height: 2.5rem;
  font-size: 0.75rem;
}

.button:hover{
  background-color: #eaf1f0;
}
.button:focus{
  outline: none!important;
  @include box-shadow-focus;
}

.button svg{
  margin-right: 0.5rem;
}

.w-1\/8{
  width: 12.5%;
}


.md\:w-1\/8{
  @include medium-up{
    width: 12.5%!important;
  }
}



.row{
  display: flex;
  margin-left: -1rem;
  margin-right: -1rem;
}

.col{
  padding-left: 1rem;
  padding-right: 1rem;
}

.col-1\/2{

}