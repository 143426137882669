.public-nav{
  height: 100vh;
  width: 256px;
	// position: fixed;
  // position: relative;
  // background-color: $green-90;
	// background-color: #d8e8e4;
	// border-right: solid 1px $gray-green-20;
  display: flex;
	flex-direction: row;
  justify-content: flex-start;
  border-bottom: none;
  z-index: 12;
  // border-bottom: solid 1px $gray-20;
	position: fixed;
	// border-right: solid 1px $sage-10;
  // box-shadow: 0px 8px 10px 0px rgba(0,0,0,0.19);
  // -webkit-box-shadow: 0px 8px 10px 0px rgba(0,0,0,0.19);
  // -moz-box-shadow: 0px 8px 10px 0px rgba(0,0,0,0.19);
  @include large-down{
    position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		max-width: 320px;
		transform: translateX(-100%);
  }
}

.mobileNavIsOpen .public-nav{
	@include large-down{
		transform: translateX(0);
  }
}

.public-nav-inner{
	display: flex;
	flex-direction: column;
	width: 100%;
	height: calc(100% - 4rem);
	border-right: solid 1px $sage-10;

	// padding: 0 0.5rem;
}

.nav-toggle{
	width: 3rem;
	height: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	border-right: solid 1px $gray-green-20;

	border-radius: 1rem;
	margin-left: auto;
	// @include box-shadow;
	&:focus{
		@include box-shadow-focus;
		outline: none!important;
	}
}

.nav-icon-col{
	display: flex;
	width: 4rem;
	height: 100vh;
	align-items: center;
	// background-color: white;
	flex-direction: column;
	position: fixed;
	top:0;
	left: 0;
	z-index: 10;
}

.nav-icon-col-item{
	height: 3rem;
	width: 3rem;
	margin: 0 0 0.5rem 0;
	border-radius: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover{
		background-color: $cream;
	}
}

.sidebar-header{
	display: flex;
	flex-direction: row;
	height: 4rem;
	align-items: center;
	border-bottom: solid 1px $sage-10;
	width: 100%;
}

.public-nav-body{
	display: flex;
	flex-direction: column;
	width: 100%;
}

.nav-brand {
  color: $green-80;
  // width: 320px;
}

.nav-link__icon-wrapper{
	display: flex;
	height: 2rem;
	width: 2rem;
	align-items: center;
	justify-content: center;
	margin-right: 0.5rem;
	// background-color: #DBE3E1;
	border-radius: 0.75rem;
}
.nav-link__icon-wrapper svg{
	height: 1.25rem;
	color: $gray-green-50;
}

.nav__link{
  // color: white;
	display: flex;
	align-items: center;
  font-size: 1rem;
  color: var(--field-label-color);
	width: 100%;
	border-radius: 0.75rem;
	padding: 0.5rem 0.5rem 0.5rem 0.25rem ;
	margin-bottom: 0.5rem;
	span{
		font-size: 1.125rem;
	}
}

.nav__link:hover{
	background-color: $gray-green-20;
	// text-decoration: underline;
}

.nav__links{
  display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
	padding: 0.5rem;
	// padding-top: 0.5rem;
  @include large-up{
    margin-left: auto;
  }
  @include medium-down{
    // display: none;
  }
}

.nav__link.nav__cta{
	margin-top: 0.5rem;
	font-weight: 600;
	display: flex;
	width: 100%;
	color: white;
	background-color: #FF7261;
}

.sightings-page .nav__links{

}