.single-sighting-map .mapboxgl-map{
	width: 100%;
	height: 100%;
}

.single-sighting-map{
	width: 100%;
}


.single-sighting-page-header.hasImage{
	.single-sighting-map{
		width: 50%;
	}
}