.form-control{
	display: inline-flex;
	align-items: center;
	height: 4rem;
	width: auto;
	border-radius: 0.5rem;
	padding-left: 0.5rem;
	margin-left: -0.5rem;
	color: var(--form-text-color);
	margin-bottom: 0.25rem;
	font-size: 1.25rem;
}

.form-control:hover{
	background-color: #edf3f0;
	background-color: var(--gray-green-20);
}

.form-control:focus-within{
	outline: dashed 1px var(--focus-ring-color)
}

.form-control:hover{
	cursor: pointer;
	/* background-color: aliceblue; */
}

.form-control .form-control__indicator-icon{
	display: none;
}

.form-control--radio .form-control__indicator-icon{
	height: 0.75rem;
	width: 0.75rem;
	background-color: var(--form-highlight);
	border-radius: 0.75rem;
}

.form-control__indicator{
	display: flex;
	width: 1.25rem;
	height: 1.25rem;
	border: solid 2px gray;
	margin-right: 0.5rem;
}

.form-control--radio .form-control__indicator{
	border-radius: 0.75rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.form-control--checkbox .form-control__indicator{
	border-radius: 0.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.form-control--checkbox .form-control__indicator-icon{
	display: flex;
	color: white;
}


/* CHECKED */
.form-control.checked .form-control__indicator-icon{
	display: flex;
}
.form-control.checked .form-control__indicator{
	border-color: var(--form-highlight);
}

.form-control--checkbox.checked .form-control__indicator{
	background-color: var(--form-highlight);
}

.form-control.checked{
	/* font-weight: 600; */
	/* background-color: white; */
}



.form-control input{
	opacity: 0;
	height: 0.1px;
	width: 0.1px;
	pointer-events: none;
}



.form-control.checked{
	/* color: var(--form-active-text-color); */
	/* font-weight: 600; */
}