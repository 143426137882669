$field-color: #dee4ed;
$field-label-color: $gray-90;
$field-highlight: $gray-green-30;





.form-field__label-optional{
  // font-size: 0.75rem;
  color: $gray-60;
  // display: inline-flex;
  // font-weight: 400;
  // margin-left: 0.25rem;
}

.form-field__helper{
  font-size: 0.75rem;
  color: $gray-80;
  font-weight: 400;
}



.form-field__validation-error{
  // background-color: $red-20;
  color: $red-90;
  // border: 2px solid $red-30;
  border-top: none;
  border-radius: 0 0 0.625rem 0.625rem;
}

.form-field {
  margin-bottom: 16px;
}

.form-field__more-info-toggle{
  font-size: 0.875rem;
  font-weight: 600;
  color: $gray-60;
}

@mixin input-styles {
  border: solid 1px $sage-10;
  color: $gray-green-90;
  background-color: white;
  padding: 1rem;
  border-radius: 0.625rem;
  font-size: 1rem;
  -webkit-appearance: none;
  @include placeholder{
    color: $gray-green-60;
    // font-weight: 600;
  }
  &:hover{
    border-color: $sage-20;
  }

}
.form-field__helper-text{
  font-size: 0.875rem;
  color: $gray-green-70;
}

.form-field__count{
  width: auto;
}

.form-field__counter{
  height: 3rem;
  width: 3rem;
  color: $gray-green-80;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $gray-green-20;
  z-index: 10;
}

.form-field__counter--increment{
  border-radius: 0 0.5rem 0.5rem 0;
}
.form-field__counter--decrement{
  border-radius: 0.5rem 0 0 0.5rem;
}

.form-field__counter:hover{
  background-color: $gray-green-30;
}
.form-field__counter:focus{
  background-color: $gray-green-40;
  outline: none;
  box-shadow: 0 0 0 2px $gray-green-60;
}

.form-field__input{
  margin-top: 4px;
  display: block;
  @include input-styles;
  width: 100%;
  height: 3.5rem;
}
.form-field-group--error .form-field__input{
  border-color: var(--red-30);
}
.form-field-group--error .form-field__input:hover{
  border-color: var(--red-40);
}

.form-field__input:focus{
  outline: none;
  border-color: $gray-green-60!important;
}

.form-field.form-field--error .form-field__input{
  border: solid 2px $red-30;
}



.form-field__text-area {
  margin-top: 4px;
  @include input-styles;
  border-radius: 0.5rem;
  display: block;
  width: 100%;
  min-height: 8rem;
  padding: 0.5rem 1rem;
}
.form-field__text-area:focus{
  outline: none;

  border-color: $gray-green-40;
  @include box-shadow-focus();
}



.form-field__card{
  background-color: white;
  border: solid 1px $sage-10;
  @apply shadow-sm;
  border-radius: 0.5rem;
  color: $gray-green-90;
}

.form-field__card:hover{
  border-color: $sage-30;
}


.form-field__radio-circle{
  display: flex;
  width: 1rem;
  height: 1rem;
  background-color: white;
  border: solid 2px $sage-10;
  border-radius: 1rem;
  margin-right: 0.5rem;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.form-field__radio-inner-circle{
  display: flex;
  width: 0.5rem;
  height: 0.5rem;
  background-color: white;
  border-radius: 1rem;
  align-items: center;
  justify-content: center;
}

.form-field__checkbox-circle {
  display: flex;
  width: 1rem;
  height: 1rem;
  background-color: white;
  border: solid 2px $sage-10;
  border-radius: 4px;
  margin-right: 0.5rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  svg{
    height: 0.75rem;
    // display: none;
  }
}
.form-field__checkbox-label.checked .form-field__checkbox-circle{
  // border-color: white;
  background-color: $green-60;
  color: white;
  border: none;
  svg{
    display: flex;
  }
}

.form-field__checkbox-label,
.form-field__radio-label{
  input{
    // visibility: hidden;
    height: 0.000001px;
    width: 0.00001px;
    // transform: translate(-1000px,-1000px);
  }
}

.form-field__checkbox-label {
  height: 4rem;
  padding: 1rem;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  background-color: white;
  border: solid 1px $sage-10;
  position: relative;
  color: $gray-green-90;
  &.selected {
    background-color: #edfaf6;
    // color: white;
  }

  svg{
    width: 0.5rem;
  }
  // input[type="checkbox"] {
  //   visibility: hidden;
  // }
}
.form-field__checkbox-label:hover {
  cursor: pointer;
  border-color: $gray-green-40;
}

.form-field__radio-label.form-field__radio-label--half {
  width: 100%;
  margin-right: 8px;
}

.form-field--half {
  display: flex;
  justify-content: space-around;
}

.form-field__radio-label.form-field__radio-label--restrained{
  min-height: 2.5rem;
  height: auto;
  background-color: transparent;
  padding: 0;
  border: none;
  width: auto;
  display: inline-flex;
  &.checked{
    color: $green-70;
    background-color: transparent;
    font-weight: 500;
  }
}

.form-field__radio-label {
  // width: 64px;
  width: 100%;
  height: 4rem;
  display: inline-flex;
  color: $gray-green-90;
  // justify-content: center;
  align-items: center;
  background-color: white;
  text-align: center;
  padding: 1rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 0.5rem;
  border: solid $sage-10 1px;
  &:hover{
    // background-color: #edfaf6;
    border-color: $gray-green-40;
  }
  // &:focus{
  //   outline-color: $green-70;
  //   @include box-shadow-focus();
  // }
  // &:last-child {
  //   margin-right: 0;
  // }
  input{
    // display: none;
  }
}
.form-field__radio-label.checked,
.form-field__checkbox-label.checked {
  border-color: $gray-green-40;
  background-color: $gray-green-10;
}

.form-field__radio-label:focus-within,
.form-field__checkbox-label:focus-within{
  box-shadow: 0 0 0 1px $gray-green-60;
}

.form-field__radio-input{
  height: 0.000001px;
  width: 0.00001px;
  // transform: translate(-1000px,-1000px);
  position: absolute;
  left: -10000px;
  opacity: 0;
  // top: -10000px;
}

.form-field__radio-input:focus + .form-field__radio-label{
  box-shadow: 0 0 0 1px $gray-green-60;
}

.form-field__radio-label input:focus,
.form-field__checkbox-label input:focus{
  outline: none;
}


.form-field__radio-label.checked .form-field__radio-circle,
.form-field__checkbox-label.checked .form-field__checkbox-circle{
  background-color: $green-60;
  border-color: $green-60;
}
.form-field__radio-label input[type="radio"] {
  // visibility: hidden;
}

.form-field__radio-label:hover {
  cursor: pointer;
}

.form-field__radio-group-input {
  width: 100%;
  min-width: unset;
}



.form-notice {
  background-color: white;
  padding: 16px;
}

.form-step__footer {
  // position: absolute;
  // bottom: 0;
  // left: 0;
  // width: 100%;
}

.mapFilters {
  display: flex;
  // padding: 0 16px;
  align-items: center;
}

select {
  width: 100%;
  height: 2.5rem;
  @include input-styles();
}

.filter-select {
  height: 32px;
  padding-left: 4px;
  padding-right: 4px;
  font-weight: 500;
  // width: 128px;
  max-width: 128px;
  width: 100%;
  color: $green-90;
  font-size: 0.75rem;
  background: white;
  border: solid 1px $gray-20;
}

.sort-select{
  height: 1.5rem;
}
