.report-page{
	background-color: var(--sage-5);
	min-height: 100vh;
}


.container-with-sidebar{
	max-width: 640px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 0 1rem;
	@include medium-up{
		// padding: 1rem;
	}
}



.report-map{
	border-radius: 0.5rem;
	// margin-top: 1rem;
}



#report-form-geocoder.hasError input{
	-moz-box-shadow: 0 0 2px var(--red-40);
	-webkit-box-shadow: 0 0 2px  var(--red-40);
	box-shadow: 0 0 0 2px var(--red-40);

}

#report-form-geocoder input{
	height: 3rem;
	padding-left: 2rem;
	border-radius: 8px;
	font-family: var(--main-font-family);
	font-size: 1rem;
}



.use-location-button{
	color: var(--gray-60);
	padding: 8px 12px;
	font-size: 0.75rem;
	border-radius: 8px;
	background-color: #EEECE8;
}

.use-location-button:hover{
	cursor: pointer;
	background-color: #e2ddd3;
}

// .error{
// 	background-color: var(--red-40);
// 	color: $red-90;
// 	border-radius: 8px;
// 	padding: 12px;
// }

.form-field__radio-label.field--count{
	height: 3rem;
	width: 3rem;
	align-items: center;
	justify-content: center;
} 