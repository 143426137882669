$modal-gutter: 32px;

.sighting-modal {
  pointer-events: initial;
  z-index: 1000;
  background-color: white;
  // width: 600px;
  // height: 800px;
  display: flex;
}

.sighting-modal-wrapper {
  // background-color: $cream;
  pointer-events: none;
  // height: 100%;
  border-radius: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 50%);
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  display: flex;
  @include small{
    top: calc(50% - 4rem);
  }

}

.sighting-modal__loading {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sighting-modal__body {
  // display: flex;
  padding: 16px;
  flex-direction: column;
}



.sighting-modal {
  z-index: 1000;
  position: relative;
  // background: $cream;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 320px;

  @include small{
    width: 100%;
    max-width: 320px;
  }
}

.sighting-modal__backdrop {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  content: "";
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  display: block;
  background-color: rgba(62, 100, 91, 0.2);
  backdrop-filter: blur(3px);
}

.modal-header {
  background-color: mintcream;
  display: block;
  position: relative;
}
.modal-header::before{
  float: left;
  width: 1px;
  height: 0;
  margin-left: -1px;
  content: '';
  padding-bottom: 66%;
}
.modal-header__inner{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}



.sighting-modal__close {
  position: absolute;
  background-color: white;
  top: 0;
  right: 0;
  margin: 1rem;
  height: 3rem;
  width: 3rem;
  border-radius: 1rem;
  @include box-shadow;
  color: $green-100;
}
.sighting-modal__close:hover{
  background-color: $sage-10;
}

.sighting-modal__before-heading {
  display: flex;

}


.flags {
  display: inline-block;
  margin-left: auto;
  margin-right: 0;
}
