
/* Hide scrollbar for Chrome, Safari and Opera */
.sighting-map-wrapper::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none;
}


.map\:loading{
  .map-search-wrapper{
    display: none;
  }
}
// .map-sidebar-wrapper{
//   height: 100vh;
// }

.sighting-map-wrapper, body{
  -ms-overflow-style: none;
  scrollbar-width: none;  
}

.sighting-map-wrapper{
  // top: 4rem;
  height: 100vh;
  @include xl-up{
    position:fixed;
  }

  @include large-down{
    position:fixed;
    top: 4rem;
  }
}
// ███████╗███╗   ███╗ █████╗ ██╗     ██╗     
// ██╔════╝████╗ ████║██╔══██╗██║     ██║     
// ███████╗██╔████╔██║███████║██║     ██║     
// ╚════██║██║╚██╔╝██║██╔══██║██║     ██║     
// ███████║██║ ╚═╝ ██║██║  ██║███████╗███████╗
// ╚══════╝╚═╝     ╚═╝╚═╝  ╚═╝╚══════╝╚══════╝
// < 480 PX       
.app-wrapper.sighting-map-page{
  @include small{
    height: 100vh;
    overflow: hidden;
  }
}
.app-wrapper.sighting-map-page.mobileFilterMenuIsOpen{
  @include small{
    height: unset;
    overflow: unset;
    .map-sidebar-wrapper {
      // display: none;
      // flex-direction: column-reverse;
      height: auto;
      // overflow-y: scroll;

    }
  }
}

@include small {
  .map-sidebar-wrapper {
    // flex-direction: column-reverse;
    // height: 100vh;
    // overflow-y: hidden;
  }

  .sighting-map-wrapper{
    position: fixed;
    // z-index: 100;
    // margin-top: 4rem;
    // overflow: hidden;
  }
  #sighting-map, #sighting-map .mapboxgl-canvas{
    width: 100vw;
    height: 100vh;
  }
  // .map-wrapper,
  // .mapboxgl-map {
  //   width: $mobile-map-width;
  //   height: 50vh;
  // }
}

.mapboxgl-ctrl-zoom-out,
.mapboxgl-ctrl-zoom-in,
.mapboxgl-ctrl-top-right{
  @include medium-down{
    display: none;
  }
}
.sidebar::-webkit-scrollbar {
  display: none;
}
.sidebar{
  position: fixed!important;
  /* Hide scrollbar for Chrome, Safari and Opera */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


@include medium-down{
  .sighting-map-wrapper{
    // top: 0;
    top:4rem;
  }
}

@include large-down{
  .sighting-map-wrapper, .sighting-map-wrapper .mapboxgl-map {
    width: 100vw;
  }
}

@include xl { 
  .sidebar{
    width: 256px;
    backdrop-filter: 2px;
    // border-right: solid 1px #e5e5e5;
  }
  .sighting-map-wrapper, .sighting-map-wrapper .mapboxgl-map {
    width: calc(100vw - 256px);
    width: 100vw;
  }
}


.mobileFilterMenuIsOpen .sighting-map-wrapper{
  @include medium-down{
    height: 100%;
    // top: 0;
    overflow-y: scroll;
  }
  @include small{
    // z-index: 1000000;
    overflow-y: scroll;
  }
}


#sighting-map {
  height: calc(100vh - 4rem);
  // top: 4rem;
  width: calc(100vw - 256px);
  position: relative;
  @include large-down{
    width: 100vw;
    height: calc(100vh - 4rem);
  }
}

// @include small{
//   .sidebar{
//     display: none;
//     width: 100vw;
//     height: auto;
//     position:relative;
//     margin-top: 4rem;
//     max-width: unset;
//     padding-top: 0;
//     z-index: 101
//   }
// }

.app-wrapper.map-tab\:list{
  @include small{
    .sidebar{
      display: flex;

    }
    #sighting-map{
      display: none;
    }
    .sidebarSightings{
      padding-bottom: 4rem;
    }
  }
}

.map-tab__button{
  font-size: 0.75rem;
  font-weight: 500;
  background-color: white;
  height: 3rem;
  display: flex;
  @include box-shadow;
  align-items: center;
  color: $green-80;
  border-radius: 1.5rem;
  padding: 0 1rem;
  font-weight: 500;
  pointer-events: all;
}



.map-wrapper {
  /* width: 100vw; */
  position: relative;
}

.map-tab-bar{
  display: none;
  pointer-events: none;
  @include small{
    justify-content: space-evenly;
    height: 4rem;
    display: flex;
    position: fixed;
    z-index: 1000000000000;
    bottom: 0;
    width: 100%;
    // background-color: white;
  }
}

.app-wrapper.mobileFilterMenuIsOpen{
  // overflow: hidden;
  height: auto;
}

.app-wrapper.mobileFilterMenuIsOpen{
  // overflow: hidden;
  .map-tab-bar{
    display: none;
  }
}

.mobileFilterMenuIsOpen .public-nav{
  @include medium-down{
    // display: none;
  }
}

.mobileFilterMenuIsOpen .sidebar{
  @include medium-down{
    // display: none;
  }
}

.mobileFilterMenuIsOpen #sighting-map{
  @include small{
    // display: none;
  }
}
.mobileFilterMenuIsOpen .sidebar{
  @include small{
    // display: none!important;
  }
}



.sidebar-sightings {
  height: 100%;
}

.loading-skeleton.sighting-map__loading-skeleton{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: $gray-10;
  z-index: 1000000;
}

.sidebar {
	// background-color: #f9f8f5;
  // background-color: #f5f9f8;
  display: flex;
  z-index: 10;
  // background-color: $cream;
  background-color: transparent;
  height: calc(100vh - 48px);
  overflow-y: scroll;
  width: 256px;
	max-width: 640px;
	position: relative;
  padding-top: 4rem;
  @include large-up{
    width: 256px;
    position: fixed;
    top: 4rem;
    left: 0;
  }

  /* Need to set media queries for max-width 1280 */
}

.sidebar-status-marker{
  width: 0.75rem;
  height: 0.75rem;
  border: solid 1px white;
  border-radius: 0.5rem;
}

.sidebar-loading-skeleton{
  min-height: calc(100vh - 128px);
  height: 100%;
  width: 256px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left:0;
  position: absolute!important;

}


.sidebarSightings {
  display: flex;
  flex-wrap: wrap;
  padding: 1.5rem 0.75rem 0.75rem 0.75rem;
  position: relative;
  flex-direction: column;
	// margin-top: 4rem;
}


.sidebar-sightings{
	position: relative;
}

.sidebar-sort {
  display: flex;
  background-color: $cream;
  background-color: white;
  font-size: 1rem;
  height: 4rem;
  padding-left: 1.5rem;
  padding-right: 0.875rem;
  align-items: center;
  border-bottom: solid 1px #e5e5e5;
  border-right: solid 1px #e5e5e5;
	position: fixed;
	top: calc(4rem + 12px);
  width: 256px;
  width: calc(256px - 24px);
  left: 0;
  left: 12px;
  border-radius: 12px;
  border: none!important;
	z-index: 1;
	// background-color: #f0f5f3;
	// box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
	// -webkit-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
	// -moz-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
	@include small{
    background-color: transparent;
    box-shadow:none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    border: none;
		position: relative;
		top: unset;
    margin-top: 0;
    padding-left: 8px;
		z-index: unset;
    width: $mobile-map-width;
    height: auto;
    padding: 1rem 1rem 1rem 1.5rem;
	}
	// @include large-up{
	// 	height: 4rem;
	// 	position: fixed;
	// 	width: 320px;
	// 	top: 0;
	// }
}

.sidebar-sort__result{
	font-size: 0.875rem;
	color: $green-80;
	font-weight: 500;
}

.sidebar-sort .react-select {
  width: 200px;
  margin-left: auto;
  margin-right: 0;
  z-index: 10;
}

.sidebar-sort__select {
	padding: 8px;
	height: 2.5rem;
	border-radius: 8px;
	background: white;
	font-size: 0.875rem;
	color: $green-80;
  border: none;
  display: flex;
  align-items: center;
  line-height: 1;
  position: relative;
	@include box-shadow;
  -webkit-appearance: none;
  @include small{
    height: 2rem;
  }
  &:after{
    content: url('/public/sort.svg');
    position: absolute;
    width: 1rem;
    height: 1rem;
    background-color: red;
    top: 0;
    right: 0;
    position: absolute;
    z-index: 1000;
  }
  /* border: solid 2px red; */
}

.sidebar-sort__label {
}

.sidebar-heading {
  font-size: 1rem;
  margin-left: 16px;
  line-height: 2rem;
  margin-bottom: 16px;
  color: #455c52;
  font-weight: 400;
  /* font-family: "Fabriga"; */
}


.mapboxgl-canvas {
  width: 100%;
}

.mapboxgl-canvas:focus {
 outline: none;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact{
  background-color: transparent;
}
.mapboxgl-ctrl-attrib-inner{
  padding-right: 0.5rem;
  padding: 0.25rem 0.5rem;
  margin-bottom: -0.25rem;
  border-radius: 0.5rem;
  background-color: rgba(255,255,255,0.7);
}
