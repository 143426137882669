


.sighting-tag{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 2rem;
	padding:  0 1rem;
	border-radius: 1rem;
  background-color: $gray-green-10;
  color: $gray-green-70;
	border: solid 2px $gray-green-20;
	text-transform: capitalize;
	font-size: 0.875rem;
	line-height: 1;
	font-weight: 500;
	margin: 2px;
}

// .sighting-tag:last-child{
// 	margin-right: 0;
// }

.sighting-tag.sighting-tag--small{
	height: 1.5rem;
	padding: 0 0.75rem;
	font-size: 0.75rem;
}

.status-tag{
  display: inline-flex;
	text-transform: capitalize;
  align-items: center;
  height: 2rem;
  font-size: 0.875rem;
  border-radius: 1rem;
  font-weight: 500;
	line-height: 1;
  padding: 0 1rem;
	margin: 2px;
  @apply shadow;
}

.status-tag--small{
  height: 1.5rem;
  padding: 0 0.75rem;
  font-size: 0.75rem;
}

.status-tag.status-tag--general{
  background-color: $general-bg-color;
  color: $general-text-color;
}

.status-tag.status-tag--urgent{
  background-color: $urgent-bg-color;
  color: $urgent-text-color;
}

.status-tag.status-tag--rescued{
  background-color: $rescued-bg-color;
  color: $rescued-text-color;
}

.status-tag.status-tag--deceased{
  background-color: $deceased-bg-color;
  color: $deceased-text-color;
}
.status-tag.status-tag--unknown{
  background-color: #e0e0e0;
  color: #444;
}
